import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import * as api from "../api/api";



// ***************************************************************************************************
// ***************************************************************************************************
export function Login()
{
	let navigate = useNavigate();

	useEffect(() => {
		// Initialize Google Identity Services
		const initializeGoogleIdentity = async () => {
			await new Promise((resolve) => {
				const script = document.createElement('script');
				script.src = 'https://accounts.google.com/gsi/client';
				script.onload = resolve;
				document.head.appendChild(script);
			});
		};

		initializeGoogleIdentity();
	}, []);

	const handleGoogleLogin = async () => {
		try {
			// @ts-ignore - Google Identity Services types are not available
			const client = google.accounts.id.initialize({
				client_id: '24446013817-vmkh0jbrbbqj1upm5g0cnpvgqfutk8lo.apps.googleusercontent.com',
				callback: async (response: any) => {
					if (response.credential) {
						const jwt = await api.login(response.credential);
						if (jwt) {
							navigate("/");
						} else {
							alert("Не удалось войти");
						}
					}
				},
			});

			// @ts-ignore
			google.accounts.id.prompt();
		} catch (error) {
			console.error('Ошибка при входе через Google:', error);
			alert("Произошла ошибка при входе через Google");
		}
	};

	if( api.isJwtSet() )
		return <Navigate to="/" />;

	return (
		<div>
			<Button variant="primary" onClick={ handleGoogleLogin }>
				Войти через Google
			</Button>
		</div>
	);
}
